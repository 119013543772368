<script>
import { putSeckill, postSeckill } from "@/newApi/market";
import { getGoodsSelect, getGoodsAttr } from "@/newApi/commit";
export default {
  name: "deawerMarket",
  props: {
    type: {
      type: String,
      default: "add",
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: [],
      options1: [],
      value1: [],
    };
  },
  components: {},
  //生命周期
  async created() {
    console.log(this.info);
    await this._getGoodsSelect();
    if (this.type == "edit") {
      this.value1 = [this.info.start_time, this.info.end_time];
      this.info.buy_type = this.info.buy_type.toString();
      console.log(this.options, "546464");
    }
  },
  mounted() {},
  methods: {
    //商品规格下拉
    changeAttr(e) {
      console.log(e);
    },
    //商品下拉选择
    changeGoods(e) {
      this._getGoodsAttr(e);
    },
    //获取商品规格下拉
    async _getGoodsAttr(id) {
      let data = {
        id: id,
      };
      let res = await getGoodsAttr(data);
      if (res.code == 200) {
        this.options1 = res.data;
      }
    },
    //商品下拉选择
    _getGoodsSelect() {
      getGoodsSelect().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
          if (this.type == "edit") {
            this.options.forEach((item) => {
              if (item.goods_name == this.info.goods_name) {
                console.log(item, "2432554");
                this._getGoodsAttr(item.id);
              }
            });
          }
        }
      });
    },
    changeDate(e) {
      this.info.start_time = e[0];
      this.info.end_time = e[1];
      console.log(e, this.info);
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
      this.$emit("getList");
    },
    //新增
    async _postSeckill(data) {
      let res = await postSeckill(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.closeDrawer();
        this.$emit("getList");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    //修改
    async _putSeckill(data) {
      let res = await putSeckill(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.closeDrawer();
        this.$emit("getList");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    //确定保存
    handleConfirm(row) {
      if (!this.info.name) return this.$message.error("请输入名称");
      else if (this.value1.length != 2)
        return this.$message.error("请选择时间");
      else if (!this.info.spec_id) return this.$message.error("请选择商品规格");
      else if (!this.info.amount) return this.$message.error("请输入秒杀价格");
      else if (!this.info.quantity)
        return this.$message.error("请输入秒杀库存");
      else if (!this.info.buy_type)
        return this.$message.error("请选择限购类型");
      else if (this.info.buy_type == 2 && !this.info.limit_count)
        return this.$message.error("请输入限购数量");
      else {
        if (this.type == "add") {
          this._postSeckill(this.info);
        } else {
          this._putSeckill(this.info);
        }
      }
    },
  },
};
</script>

<template>
  <div class="gg-container">
    <div style="margin-bottom:20px;">
      <span style="font-size:25px;border-bottom:3px solid #1684FC;"
        >{{ type == "edit" ? "编辑" : "新增" }}秒杀活动</span
      >
    </div>
    <div class="content">
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>活动名称</span
        >
        <el-input v-model="info.name" size="mini" class="input"></el-input>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>活动时间</span
        >
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="changeDate"
        >
        </el-date-picker>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>活动商品</span
        >
        <div style="display: flex;margin-top:20px">
          <el-select
            filterable
            clearable
            v-model="info.goods_name"
            placeholder="请选择"
            size="mini"
            style="width:200px;margin:0 15px;"
            @change="changeGoods"
          >
            <el-option
              v-for="item1 in options"
              :key="item1.id"
              :label="item1.goods_name"
              :value="item1.id"
            >
            </el-option>
          </el-select>
          <el-select
            filterable
            clearable
            v-model="info.spec_id"
            placeholder="请选择规格"
            size="mini"
            style="width:200px;margin:0 15px;"
            @change="changeAttr"
          >
            <el-option
              v-for="(item, index) in options1"
              :key="index"
              :label="item.goods_attr_name"
              :value="item.goods_attr_id"
            >
            </el-option>
          </el-select>
          <div style="margin-right:20px;">
            秒杀价:
            <el-input
              v-model="info.amount"
              size="mini"
              style="width:100px"
            ></el-input>
          </div>
          <div>
            秒杀库存:
            <el-input
              v-model="info.quantity"
              size="mini"
              style="width:100px"
            ></el-input>
          </div>
        </div>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>每人限购数量</span
        >
        <div style="display: flex;">
          <div style="display: flex;flex-direction: column;">
            <el-radio v-model="info.buy_type" label="1">不限数量</el-radio>
            <el-radio v-model="info.buy_type" label="2" style="margin:10px 0">
              <el-input
                v-model="info.limit_count"
                size="mini"
                style="width:100px"
              ></el-input
              >个</el-radio
            >
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="closeDrawer">取 消</el-button>
      <el-button type="primary" size="small" @click="handleConfirm('info')">{{
        type == "add" ? "确 定" : "修 改"
      }}</el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input {
  width: 200px;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
</style>
