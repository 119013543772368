<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                      background-color: #2362FB; border-color: #2362FB"
            @click="handleAddDelEdit('add', {})"
            >新增
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                      background-color: #2362FB; border-color: #2362FB"
            @click="handleAddDelEdit('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >修改
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                      background-color: #2362FB; border-color: #2362FB"
            @click="handleAddDelEdit('del', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                        background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                        background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click="getInfo(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              v-if="'status' == column.column_prop"
              class="switch"
              @change="changeStatus(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              @click="under(scope.row.cover, 'pic' + scope.$index)"
              v-if="scope.row.cover"
            >
              <a style="color:#2362FB" @click="img">查看图片</a>
              <el-image
                :ref="'pic' + scope.$index"
                style="width: 0; height: 0;"
                :z-index="zIndex"
                :src="scope.row.cover"
                :preview-src-list="[scope.row.cover]"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'order_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.order_status)
                "
                v-model="searchParams.order_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'username' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.username"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.username)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
      :append-to-body="true"
      size="76%"
      :visible.sync="dialogTableVisible"
      direction="rtl"
      @close="close"
    >
      <seckillDeawer
        @closeDrawer="closeDrawer"
        @getList="getList"
        ref="seckillDeawer"
        v-if="dialogTableVisible"
        :type="type"
        :info="info"
      />
    </el-drawer>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import seckillDeawer from "./components/seckillDeawer.vue";
import {
  getSeckillList,
  postBannerStatus,
  deleteSeckill,
} from "@/newApi/market";
import { Message } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "marketList",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  computed:{
    ...mapState({
      table_options: state => state.user.table_options,
    })

  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type:'seckillActivity',
      operateList: [],
      dialogTableVisible: false,
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "type",
          isSearch: false,
        },
        {
          name: "status",
          isSearch: false,
        },
        {
          name: "username",
          isSearch: false,
        },
        {
          name: "order_sn",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
      flag: false,
      zIndex: 999,
      type: "add",
      info: {},
    };
  },
  components: {
    Pagination,
    editTableHead,
    seckillDeawer,
  },

  methods: {
    close() {
      console.log(123);
      this.getList();
    },
    //关闭弹窗,刷新列表
    closeDrawer(e) {
      console.log(e, "e453423");
      this.dialogTableVisible = false;
    },
    //删除
    _deleteSeckill(data) {
      //提示框
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSeckill(data).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增删除编辑
    handleAddDelEdit(type, row) {
      if (type == "add") {
        this.dialogTableVisible = true;
        this.type = "add";
        console.log("新增");
      } else if (type == "edit") {
        console.log("编辑");
        this.dialogTableVisible = true;
        this.type = "edit";
        this.info = row;
      } else {
        console.log("删除");
        let data = {
          ids: row.id,
        };
        this._deleteSeckill(data);
      }
    },
    img() {
      this.flag = true;
    },
    under(pics, refPic = "pic") {
      // console.log(pics,refPic);
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    changeStatus(row) {
      let data = {
        id: row.id,
        status: row.status,
      };
      postBannerStatus(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "seckillActivity",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.seckillActivity;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;

      getSeckillList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    getInfo(row) {
      this.$router.push({
        path: "/shopOrderMamage/afterInfo",
        query: {
          id: row.id,
          originOrder: "customer",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>
